<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            :disabled="patrolInfo.typeDisabled"
            codeGroupCd="PATROL_TYPE_FST_CD"
            itemText="codeName"
            itemValue="code"
            label="상위구분"
            name="patrolFstCd"
            v-model="searchParam.patrolFstCd">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            codeGroupCd="PATROL_TYPE_SEC_CD"
            itemText="codeName"
            itemValue="code"
            label="하위구분"
            name="patrolSecCd"
            v-model="searchParam.patrolSecCd">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            label="추진부서"
            name="deptCd"
            v-model="searchParam.deptCd"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 추진기간 -->
          <c-datepicker
            :range="true"
            label="추진기간"
            defaultStart="-7d"
            defaultEnd="0d"
            name="period"
            v-model="searchParam.period"
          />
        </div>
      </template>
    </c-search-box>
    <!-- 지적사항 목록 -->
    <c-table
      ref="table"
      title="지적사항 목록"
      :editable="false"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="grid.data"
      :changeData="changeData"
      @linkClick="(row, col, idx) => linkClick(row, row.ibmClassCd, row.sopImprovementId)"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'patrol-status',
  props: {
    patrolInfo: {
      type: Object,
      default(){
        return {
          patrolFstCd: null,
          typeDisabled: false,
        }
      },
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'deptCd' },
          { index: 1, colName: 'fstMergeGroup' },
          // { index: 2, colName: 'secMergeGroup' },
        ],
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            // 추진부서
            label: '추진부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'patrolFstName',
            field: 'patrolFstName',
            // 상위구분
            label: '상위구분',
            align: 'center',
            sortable: true,
            style: 'width:90px',
          },
          {
            name: 'patrolSecName',
            field: 'patrolSecName',
            // 하위구분
            label: '하위구분',
            align: 'center',
            sortable: true,
            style: 'width:90px',
          },
          {
            name: "beforeImprPath",
            field: 'beforeImprPath',
            label: '지적사진<br>(개선 전 사진)',
            align: 'center',
            type: 'attach',
            customSize: 'width: 70px !important;height: 70px !important',
            taskClassCd: 'IBM_BEFORE',
            keyText: 'sopImprovementId',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '지적사항(요청내용)',
            align: 'left',
            type: 'link',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'actionContents',
            field: 'actionContents',
            // 조치내용
            label: '조치내용',
            align: 'left',
            style: 'width:180px',
            sortable: true,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            // 요청일
            label: '조치완료<br>요청일',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치완료일',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: "afterImprPath",
            field: 'afterImprPath',
            label: '개선 후 사진',
            align: 'center',
            type: 'attach',
            taskClassCd: 'IBM_AFTER',
            customSize: 'width: 70px !important;height: 70px !important',
            keyText: 'sopImprovementId',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        deptCd: null,
        patrolFstCd: null,
        patrolSecCd: null,
        useFlag: 'Y',
        startYmd: '',
        endYmd: '',
        period: [],
      },
      searchUrl: '',
      editable: true,
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      changeData: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      if (this.patrolInfo.typeDisabled) {
        this.searchParam.patrolFstCd = this.patrolInfo.patrolFstCd
      }
      this.listUrl = selectConfig.sai.patrol.graph.result.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      if (this.searchParam.period && this.searchParam.period.length === 2) {
        this.searchParam.startYmd = this.searchParam.period[0];
        this.searchParam.endYmd = this.searchParam.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        this.changeData = !this.changeData
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, ibmClassCd, sopImprovementId) {
      if (ibmClassCd === 'IC00000001') {
        this.popupOptions.title = 'LBLIMPROVE';
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      } else {
        this.popupOptions.title = 'LBLIMMEDIATE';
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      }
      this.popupOptions.param = {
        sopImprovementId: sopImprovementId,
      };
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
      // this.popupOptions.title = '관리책임자 패트롤 상세'; // 관리책임자 패트롤 상세
      // this.popupOptions.param = {
      //   saiPatrolId: row ? row.saiPatrolId : ''
      // };
      // this.popupOptions.target = () => import(`${'./patrolDetail.vue'}`);
      // this.popupOptions.visible = true;
      // this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
